import React from 'react';
import styled from 'styled-components';
import { minBreakpointQuery } from '../styles';
import { Container } from './ui';
import ProductGallery from './ProductGallery';
import ProductSummaryForm from './ProductSummaryForm';

const StyledProductDetail = styled.div`
  margin: 20px 0;

  ${minBreakpointQuery.small`
    margin-top: 25px;
    margin-bottom: 25px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 30px;
    margin-bottom: 30px;
  `}
`;

const StyledInner = styled.div`
  display: grid;
  grid-template-columns: 100%;
  gap: 30px;

  ${minBreakpointQuery.tiny`
    gap: 40px;
  `}

  ${minBreakpointQuery.mlarge`
    grid-template-columns: 49% calc(51% - 40px);
  `}

  ${minBreakpointQuery.large`
    gap: 50px;
    grid-template-columns: 49% calc(51% - 50px);
  `}
`;

const ProductDetail = ({
  storefrontId,
  images,
  title,
  collections,
  filling,
  tags,
  hasOnlyDefaultVariant,
  options,
  variants,
  quantityDiscounts,
  isBestSeller,
  isAntiAllergy,
  isInSale,
  isRetailProduct,
}) => (
  <StyledProductDetail>
    <Container>
      <StyledInner>
        <ProductGallery
          images={images}
          title={title}
          variants={variants}
          isBestSeller={isBestSeller}
          isAntiAllergy={isAntiAllergy}
          isInSale={isInSale}
        />
        <ProductSummaryForm
          storefrontId={storefrontId}
          title={title}
          collections={collections}
          filling={filling}
          tags={tags}
          hasOnlyDefaultVariant={hasOnlyDefaultVariant}
          options={options}
          variants={variants}
          quantityDiscounts={quantityDiscounts}
          isRetailProduct={isRetailProduct}
        />
      </StyledInner>
    </Container>
  </StyledProductDetail>
);

export default ProductDetail;

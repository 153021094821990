import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
  standardTransition,
  visuallyHidden,
} from '../styles';
import TierPricing from './TierPricing';
import ProductQuantity from './ProductQuantity';
import { Button } from './ui';
import { StoreContext } from '../context/StoreContext';

const StyledProductForm = styled.form``;

const StyledOptions = styled.div`
  margin-bottom: 20px;

  ${minBreakpointQuery.small`
    margin-bottom: 25px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 30px;
  `}
`;

const StyledOption = styled.label`
  display: block;
  margin-bottom: 10px;

  ${minBreakpointQuery.small`
    margin-bottom: 15px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 20px;
  `}
`;

const StyledOptionHeading = styled.span`
  color: ${standardColours.black};
  font-weight: ${fontWeights.bold};
`;

const StyledFiltersOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 6px;
`;

const StyledFiltersOption = styled.div`
  margin: 4px 8px 4px 0;

  &:last-child {
    margin-right: 0;
  }
`;

// moved up for checked styling
const StyledFiltersText = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 22px;
  font-weight: ${fontWeights.bold};
  line-height: 1.4;
  text-transform: uppercase;
  background-color: ${brandColours.secondary};
  cursor: pointer;
  transition: ${standardTransition('color')},
    ${standardTransition('background-color')};
`;

const StyledFiltersInput = styled.input`
  ${visuallyHidden()};

  &:checked + ${StyledFiltersText} {
    color: ${standardColours.black};
    background-color: ${brandColours.primary};
  }
`;

const StyledDimensions = styled.p`
  color: ${standardColours.darkGrey};
  ${fontSize(14)};
`;

const StyledQuantityButton = styled.div`
  display: flex;
  margin-top: 30px;

  ${minBreakpointQuery.small`
    margin-bottom: 35px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 40px;
  `}
`;

const StyledButton = styled(Button)`
  margin-left: 20px;
  width: 100%;

  &:disabled {
    opacity: 0.6;
  }
`;

const ProductForm = ({
  hasOnlyDefaultVariant,
  options,
  variants,
  quantityDiscounts: productQuantityDiscounts,
  selectedVariant,
  setSelectedVariant,
  ...props
}) => {
  const { addProductsToCheckout } = useContext(StoreContext);

  const [selectedVariantOptions, setSelectedVariantOptions] = useState({
    ...selectedVariant.selectedOptions,
  });
  const [quantity, setQuantity] = useState(1);
  const [addingToBasket, setAddingToBasket] = useState(false);

  const quantityDiscounts = selectedVariant.quantityDiscounts
    ? JSON.parse(selectedVariant.quantityDiscounts.value)
    : productQuantityDiscounts
      ? JSON.parse(productQuantityDiscounts.value)
      : [];

  useEffect(() => {
    const selectedVariant = variants.find(
      ({ selectedOptions }) =>
        JSON.stringify({ ...selectedOptions }) ===
        JSON.stringify(selectedVariantOptions),
    );

    setSelectedVariant(selectedVariant);
  }, [variants, selectedVariantOptions, setSelectedVariant]);

  const changeQuantity = (e, minQuantity = 1) => {
    const value = e.target.value;

    if (value === 'remove') {
      setQuantity(quantity > minQuantity ? quantity - 1 : minQuantity);
    } else if (value === 'add') {
      setQuantity(quantity ? quantity + 1 : minQuantity);
    } else {
      setQuantity(parseInt(value));
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const lineItems = [
      {
        variantId: selectedVariant.storefrontId,
        quantity: quantity || 1,
      },
    ];

    setAddingToBasket(true);

    await addProductsToCheckout(lineItems);

    setAddingToBasket(false);

    setQuantity(1);
  };

  return (
    <StyledProductForm {...props} onSubmit={e => handleSubmit(e)}>
      {!hasOnlyDefaultVariant && options.length > 0 && (
        <StyledOptions>
          {options.map(({ name, values }, i) => (
            <StyledOption key={i}>
              <StyledOptionHeading>
                Select a {name.toLowerCase()}:
              </StyledOptionHeading>
              <StyledFiltersOptions>
                {values.map((value, id) => (
                  <StyledFiltersOption key={id}>
                    <StyledFiltersInput
                      type="radio"
                      id={value}
                      name={name}
                      value={value}
                      checked={value === selectedVariantOptions[i].value}
                      onChange={() =>
                        setSelectedVariantOptions({
                          ...selectedVariantOptions,
                          [i]: {
                            name,
                            value,
                          },
                        })
                      }
                    />
                    <StyledFiltersText for={value}>{value}</StyledFiltersText>
                  </StyledFiltersOption>
                ))}
              </StyledFiltersOptions>
            </StyledOption>
          ))}
          {selectedVariant.dimensions && (
            <StyledDimensions>
              Size: {selectedVariant.dimensions.value}
            </StyledDimensions>
          )}
        </StyledOptions>
      )}
      <TierPricing
        variant={selectedVariant}
        quantityDiscounts={quantityDiscounts}
      />
      <StyledQuantityButton>
        <ProductQuantity
          quantity={quantity}
          quantityDiscounts={quantityDiscounts}
          setQuantity={setQuantity}
          changeFunction={changeQuantity}
        />
        <StyledButton alt={true} disabled={addingToBasket}>
          {addingToBasket ? 'Adding...' : 'Add to Basket'}
        </StyledButton>
      </StyledQuantityButton>
    </StyledProductForm>
  );
};

export default ProductForm;

import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  sectionMargins,
  standardColours,
  brandColours,
} from '../styles';
import { Container, Heading, HtmlContent } from './ui';

const StyledProductSpecificationsDelivery = styled.section`
  ${sectionMargins()};
`;

const StyledInner = styled.div`
  display: grid;
  gap: 30px;

  ${minBreakpointQuery.smedium`
    grid-template-columns: 1fr 1.15fr;
    gap: 40px;
  `}

  ${minBreakpointQuery.medium`
    gap: 60px;
  `}

  ${minBreakpointQuery.large`
    gap: 80px;
  `}

  ${minBreakpointQuery.xlarge`
    gap: 100px;
  `}

  ${minBreakpointQuery.xxlarge`
    gap: 120px;
  `}
`;

const StyledContent = styled.div`
  ${minBreakpointQuery.smedium`
    margin-top: 30px;
    margin-bottom: 30px;
  `}
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 20px;

  ${minBreakpointQuery.small`
    margin-bottom: 30px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 40px;
  `}
`;

const StyledTable = styled.table`
  margin-top: 20px;
  width: 100%;
  border-top: 2px solid ${brandColours.senary};

  ${minBreakpointQuery.small`
    margin-top: 25px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 30px;
  `}
`;

const StyledTableRow = styled.tr`
  display: flex;
  gap: 20px;
  padding: 10px;
  border-bottom: 2px solid ${brandColours.senary};

  ${minBreakpointQuery.small`
    padding: 14px;
  `}

  ${minBreakpointQuery.large`
    padding: 18px;
  `}

  &:nth-child(2n) {
    background-color: ${standardColours.lighterGrey};
  }
`;

const StyledTableHeader = styled.th`
  width: 40%;
  text-align: right;
`;

const StyledTableData = styled.td`
  width: 60%;
  border: none;
`;

const ProductSpecificationsDelivery = ({
  specificationsContent,
  specificationsData,
  deliveryContent,
}) => (
  <StyledProductSpecificationsDelivery>
    <Container>
      <StyledInner>
        <StyledContent>
          <StyledHeading>Product Specifications</StyledHeading>
          <HtmlContent html={specificationsContent} />
          <StyledTable>
            {specificationsData.colour && (
              <StyledTableRow>
                <StyledTableHeader>Colour: </StyledTableHeader>
                <StyledTableData>{specificationsData.colour}</StyledTableData>
              </StyledTableRow>
            )}
            {specificationsData.coverMaterial && (
              <StyledTableRow>
                <StyledTableHeader>Cover Material: </StyledTableHeader>
                <StyledTableData>
                  {specificationsData.coverMaterial}
                </StyledTableData>
              </StyledTableRow>
            )}
            {specificationsData.filling && (
              <StyledTableRow>
                <StyledTableHeader>Filling: </StyledTableHeader>
                <StyledTableData>{specificationsData.filling}</StyledTableData>
              </StyledTableRow>
            )}
            {specificationsData.weight && (
              <StyledTableRow>
                <StyledTableHeader>Weight: </StyledTableHeader>
                <StyledTableData>{specificationsData.weight}</StyledTableData>
              </StyledTableRow>
            )}
            {specificationsData.size && (
              <StyledTableRow>
                <StyledTableHeader>Size: </StyledTableHeader>
                <StyledTableData>{specificationsData.size}</StyledTableData>
              </StyledTableRow>
            )}
            {specificationsData.packaging && (
              <StyledTableRow>
                <StyledTableHeader>Packaging: </StyledTableHeader>
                <StyledTableData>
                  {specificationsData.packaging}
                </StyledTableData>
              </StyledTableRow>
            )}
            {specificationsData.technical && (
              <StyledTableRow>
                <StyledTableHeader>Technical: </StyledTableHeader>
                <StyledTableData>
                  {specificationsData.technical}
                </StyledTableData>
              </StyledTableRow>
            )}
            {specificationsData.backing && (
              <StyledTableRow>
                <StyledTableHeader>Backing: </StyledTableHeader>
                <StyledTableData>{specificationsData.backing}</StyledTableData>
              </StyledTableRow>
            )}
            {specificationsData.fitting && (
              <StyledTableRow>
                <StyledTableHeader>Fitting: </StyledTableHeader>
                <StyledTableData>{specificationsData.fitting}</StyledTableData>
              </StyledTableRow>
            )}
            {specificationsData.threadCount && (
              <StyledTableRow>
                <StyledTableHeader>Thread Count: </StyledTableHeader>
                <StyledTableData>
                  {specificationsData.threadCount}
                </StyledTableData>
              </StyledTableRow>
            )}
            {specificationsData.fireRetardancy && (
              <StyledTableRow>
                <StyledTableHeader>Fire Retardancy: </StyledTableHeader>
                <StyledTableData>
                  {specificationsData.fireRetardancy}
                </StyledTableData>
              </StyledTableRow>
            )}
            {specificationsData.finish && (
              <StyledTableRow>
                <StyledTableHeader>Finish: </StyledTableHeader>
                <StyledTableData>{specificationsData.finish}</StyledTableData>
              </StyledTableRow>
            )}
            {specificationsData.washCare && (
              <StyledTableRow>
                <StyledTableHeader>Wash Care: </StyledTableHeader>
                <StyledTableData>{specificationsData.washCare}</StyledTableData>
              </StyledTableRow>
            )}
          </StyledTable>
        </StyledContent>
        <StyledContent>
          <StyledHeading>Delivery Information</StyledHeading>
          <HtmlContent html={deliveryContent} />
        </StyledContent>
      </StyledInner>
    </Container>
  </StyledProductSpecificationsDelivery>
);

export default ProductSpecificationsDelivery;

import React, { useState } from 'react';
import styled from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  maxBreakpointQuery,
  standardColours,
  fontWeights,
  fontSize,
  fluidFontSize,
} from '../styles';
import { VariantPrice } from './Prices';
import Stock from './Stock';
import ProductFillingRanges from './ProductFillingRanges';
import ProductForm from './ProductForm';

const StyledProductSummaryForm = styled.section``;

const StyledName = styled.h1`
  color: ${standardColours.black};
  font-weight: ${fontWeights.regular};
  ${fluidFontSize(
    '24px',
    '44px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
`;

const StyledInfo = styled.div`
  margin-top: 16px;

  ${minBreakpointQuery.xlarge`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
  `}
`;

const StyledSkuFillingRanges = styled.div``;

const StyledSku = styled.p`
  margin-bottom: 5px;
  color: ${standardColours.darkGrey};
  ${fontSize(15)};

  ${minBreakpointQuery.xlarge`
    margin-bottom: 8px;
    text-align: right;
  `}
`;

const StyledFillingRanges = styled(ProductFillingRanges)`
  justify-content: space-between;
`;

const StyledStock = styled(Stock)`
  margin: 12px 0;

  ${minBreakpointQuery.xlarge`
    margin-right: auto;
  `}
`;

const StyledPrice = styled(VariantPrice)`
  white-space: nowrap;

  ${maxBreakpointQuery.xlarge`
    margin-top: 12px;
  `}
`;

const StyledForm = styled(ProductForm)`
  margin-top: 20px;

  ${minBreakpointQuery.tiny`
    margin-top: 25px;
  `}

  ${minBreakpointQuery.xlarge`
    margin-top: 30px;
  `}
`;

const ProductSummaryForm = ({
  storefrontId,
  title,
  collections,
  filling,
  tags,
  hasOnlyDefaultVariant,
  options,
  variants,
  quantityDiscounts,
  isRetailProduct,
}) => {
  const [selectedVariant, setSelectedVariant] = useState(variants[0]);

  const isHuggleland = collections.find(
    ({ handle }) => handle === 'huggleland'
  );

  return (
    <StyledProductSummaryForm>
      <StyledName>{title}</StyledName>
      <StyledInfo>
        {(selectedVariant.sku || !isHuggleland) && (
          <StyledSkuFillingRanges>
            {selectedVariant.sku && !isRetailProduct && (
              <StyledSku>SKU: {selectedVariant.sku}</StyledSku>
            )}
            {!isHuggleland && (
              <StyledFillingRanges
                filling={filling}
                ranges={tags}
                isRetailProduct={isRetailProduct}
              />
            )}
          </StyledSkuFillingRanges>
        )}
        <StyledStock
          storefrontProductId={storefrontId}
          selectedVariant={selectedVariant}
        />
        <StyledPrice
          variant={selectedVariant}
          isRetailProduct={isRetailProduct}
        />
      </StyledInfo>
      <StyledForm
        hasOnlyDefaultVariant={hasOnlyDefaultVariant}
        options={options}
        variants={variants}
        quantityDiscounts={quantityDiscounts}
        selectedVariant={selectedVariant}
        setSelectedVariant={setSelectedVariant}
      />
    </StyledProductSummaryForm>
  );
};

export default ProductSummaryForm;

import React, { useContext, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { brandColours, fontSize } from '../styles';
import { StoreContext } from '../context/StoreContext';

const StyledStock = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 8px 10px;
  background-color: ${({ alt }) =>
    alt ? brandColours.denary : brandColours.octonary};
  white-space: nowrap;
`;

const StyledIcon = styled.span`
  position: relative;
  display: inline-block;
  width: 21px;
  height: 21px;
  border: 1px solid;
  border-radius: 50%;

  ${({ alt }) => {
    if (alt) {
      return css`
        border-color: ${brandColours.nonary};

        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 4px;
          left: 9px;
          height: 12px;
          width: 1px;
          background-color: ${brandColours.nonary};
        }

        &:after {
          transform: rotate(-45deg);
        }

        &:before {
          transform: rotate(45deg);
        }
      `;
    } else {
      return css`
        border-color: ${brandColours.septenary};

        &:after {
          content: '';
          position: absolute;
          top: 3px;
          left: 7px;
          height: 11px;
          width: 5px;
          border: solid ${brandColours.septenary};
          border-width: 0 1px 1px 0;
          transform: rotate(45deg);
        }
      `;
    }
  }}
`;

const StyledText = styled.p`
  margin-left: 8px;
  color: ${({ alt }) => (alt ? brandColours.nonary : brandColours.septenary)};
  ${fontSize(14)};
`;

const Stock = ({ storefrontProductId, selectedVariant, ...props }) => {
  const { client } = useContext(StoreContext);

  const [selectedVariantAvailability, setSelectedVariantAvailability] =
    useState(undefined);

  useEffect(() => {
    client.product.fetch(storefrontProductId).then(product => {
      setSelectedVariantAvailability(
        product.variants.find(
          variant => variant.title === selectedVariant.title
        ).available
      );
    });
  }, [
    client.product,
    storefrontProductId,
    setSelectedVariantAvailability,
    selectedVariant,
  ]);

  return selectedVariantAvailability === true ? (
    <StyledStock {...props}>
      <StyledIcon />
      <StyledText>In stock</StyledText>
    </StyledStock>
  ) : selectedVariantAvailability === false ? (
    <StyledStock alt={true} {...props}>
      <StyledIcon alt={true} />
      <StyledText alt={true}>Out of stock</StyledText>
    </StyledStock>
  ) : (
    ''
  );
};

export default Stock;

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import {
  standardColours,
  brandColours,
  fontSize,
  fontWeights,
} from '../styles';
import { Link } from './ui';
import { formatPrice, discountPrice } from '../utils';

const StyledTierPricing = styled.section`
  margin: 15px 0;
  max-width: 460px;
`;

const StyledTable = styled.table`
  width: 100%;
  color: ${standardColours.black};
  text-align: center;
  background-color: ${standardColours.lightGrey};
`;

const StyledCaption = styled.caption`
  padding: 12px 10px;
  font-weight: ${fontWeights.bold};
  ${fontSize(13)};
  background-color: ${standardColours.lightGrey};
`;

const commonTableCellStyles = () => {
  return css`
    padding: 10px;
    width: calc(100% / 3);
    ${fontSize(13)};
    border-top: 2px ${standardColours.white} solid;
    border-left: 2px ${standardColours.white} solid;

    &:first-child {
      border-left: 0;
    }
  `;
};

const StyledTableHeader = styled.th`
  ${commonTableCellStyles()};
  font-weight: ${fontWeights.semibold};
`;

const StyledTableData = styled.td`
  ${commonTableCellStyles()};
`;

const StyledText = styled.p`
  margin-top: 10px;
  ${fontSize(13)};
  text-align: right;
`;

const StyledLink = styled(Link)`
  color: ${brandColours.tertiary};
`;

const TierPricing = ({ variant, quantityDiscounts }) => {
  const { datoCmsContact } = useStaticQuery(graphql`
    query TierPricingQuery {
      datoCmsContact {
        ...LinkFragment
      }
    }
  `);

  const { price } = variant;

  return (
    quantityDiscounts.length > 0 && (
      <StyledTierPricing>
        <StyledTable>
          <StyledCaption>
            Multibuy Discount - {quantityDiscounts[0].split('|')[0]} minimum
            quantity
          </StyledCaption>
          <tbody>
            <tr>
              <StyledTableHeader>Quantity</StyledTableHeader>
              <StyledTableHeader>Ex VAT</StyledTableHeader>
              <StyledTableHeader>Inc VAT</StyledTableHeader>
            </tr>
            {quantityDiscounts.map(quantityDiscount => {
              const [quantity, discount] = quantityDiscount.split('|');

              return (
                <tr>
                  <StyledTableData>{quantity} +</StyledTableData>
                  <StyledTableData>
                    {formatPrice(discountPrice(price, discount))}
                  </StyledTableData>
                  <StyledTableData>
                    {formatPrice(discountPrice(price * 1.2, discount))}
                  </StyledTableData>
                </tr>
              );
            })}
          </tbody>
        </StyledTable>
        <StyledText>
          Looking for larger quantities?{' '}
          <StyledLink to={datoCmsContact}>Contact Us</StyledLink>
        </StyledText>
      </StyledTierPricing>
    )
  );
};

export default TierPricing;

import React, { useState } from 'react';
import { Zoom } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/zoom';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import { minBreakpointQuery, standardColours, brandColours } from '../styles';
import SaleBadge from './SaleBadge';
import bestSellerBadge from '../images/best-seller-badge.svg';
import antiAllergyBadge from '../images/anti-allergy-badge.png';

const StyledProductGallery = styled.section``;

const StyledInner = styled.div``;

const StyledMainImage = styled(GatsbyImage)`
  width: 100%;
`;

const StyledBadge = styled.img`
  position: absolute;
  bottom: 8px;
  left: 8px;
  z-index: 1;
  height: 80px;
  width: 80px;
  background-color: ${standardColours.white};
  border-radius: 50%;

  ${minBreakpointQuery.tiny`
    bottom: 9px;
    left: 9px;
    height: 90px;
    width: 90px;
  `}

  ${minBreakpointQuery.large`
    bottom: 10px;
    left: 10px;
    height: 100px;
    width: 100px;
  `}
`;

const StyledSaleBadge = styled(SaleBadge)`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
  height: 80px;
  width: 80px;
  background-color: ${standardColours.white};
  border-radius: 50%;

  ${minBreakpointQuery.tiny`
    top: 9px;
    right: 9px;
    height: 90px;
    width: 90px;
  `}

  ${minBreakpointQuery.large`
    top: 10px;
    right: 10px;
    height: 100px;
    width: 100px;
  `}
`;

const StyledNavImages = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
  margin-top: 20px;

  ${minBreakpointQuery.tiny`
    grid-template-columns: repeat(5, 1fr);
  `}

  ${minBreakpointQuery.small`
    gap: 20px;
  `}

  ${minBreakpointQuery.medium`
    grid-template-columns: repeat(6, 1fr);
  `}

  ${minBreakpointQuery.mlarge`
    grid-template-columns: repeat(4, 1fr);
  `}
`;

const StyledNavImageWrapper = styled.div`
  position: relative;
  border: 1px solid ${standardColours.grey};
  cursor: pointer;

  ${({ displayed }) => {
    if (displayed) {
      return css`
        border-color: ${brandColours.tertiary};
      `;
    }
  }}
`;

const StyledNavImage = styled(GatsbyImage)`
  aspect-ratio: 1/1;
`;

const ProductGallery = ({
  images: { mainImages, navImages },
  title,
  isBestSeller,
  isAntiAllergy,
  isInSale,
}) => {
  const [displayedImage, setDisplayedImage] = useState(0);
  const [mainGallery, setMainGallery] = useState();

  return (
    mainImages.length > 0 && (
      <StyledProductGallery>
        <StyledInner>
          <Swiper
            modules={[Zoom]}
            zoom={true}
            onSlideChange={swiper => setDisplayedImage(swiper.realIndex)}
            onSwiper={swiper => setMainGallery(swiper)}
          >
            {mainImages.map(
              (
                {
                  preview: {
                    image: { gatsbyImageData, altText },
                  },
                },
                i
              ) => (
                <SwiperSlide key={i}>
                  <div className="swiper-zoom-container">
                    <StyledMainImage
                      image={gatsbyImageData}
                      alt={altText || `${title} Image ${i + 1}`}
                      className="swiper-zoom-target"
                    />
                  </div>
                </SwiperSlide>
              )
            )}
            {(isBestSeller || isAntiAllergy) && (
              <StyledBadge
                src={isBestSeller ? bestSellerBadge : antiAllergyBadge}
                alt={isBestSeller ? 'Best Seller' : 'Anti Allergy'}
                loading="lazy"
              />
            )}
            {isInSale && <StyledSaleBadge />}
          </Swiper>
          {navImages.length > 0 && (
            <StyledNavImages>
              {navImages.map(
                (
                  {
                    preview: {
                      image: { gatsbyImageData, altText },
                    },
                  },
                  i
                ) => (
                  <StyledNavImageWrapper
                    key={i}
                    displayed={displayedImage === i}
                    onClick={() => mainGallery.slideTo(i)}
                  >
                    <StyledNavImage
                      image={gatsbyImageData}
                      alt={altText || `${title} Image ${i + 1}`}
                    />
                  </StyledNavImageWrapper>
                )
              )}
            </StyledNavImages>
          )}
        </StyledInner>
      </StyledProductGallery>
    )
  );
};

export default ProductGallery;

import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { minBreakpointQuery, standardColours, fontSize } from '../styles';
import { Container, Link } from './ui';
import { buildUrl } from '../utils';

const StyledBreadcrumbs = styled.div`
  margin: 12px 0;

  ${minBreakpointQuery.small`
    margin-top: 14px;
    margin-bottom: 14px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 16px;
    margin-bottom: 16px;
  `}
`;

const StyledList = styled.ol`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const StyledItem = styled.li`
  color: ${standardColours.black};
  ${fontSize(11)};
  white-space: nowrap;

  ${minBreakpointQuery.small`
    ${fontSize(12)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(13)};
  `}

  &:before {
    content: '/';
    margin: 0 4px;
  }

  &:first-child {
    &:before {
      content: none;
    }
  }
`;

const StyledLink = styled(Link)``;

const Breadcrumbs = ({ currentPageTitle, items = [], ...props }) => {
  if (items) {
    if (!Array.isArray(items)) {
      const treeParent = items;
      items = [];

      items.unshift({
        text: treeParent.title,
        urlPath: buildUrl(treeParent.slug, treeParent),
      });

      if (treeParent.treeParent) {
        items.unshift({
          text: treeParent.treeParent.title,
          urlPath: buildUrl(treeParent.treeParent.slug, treeParent.treeParent),
        });

        if (treeParent.treeParent.treeParent) {
          items.unshift({
            text: treeParent.treeParent.treeParent.title,
            urlPath: buildUrl(
              treeParent.treeParent.treeParent.slug,
              treeParent.treeParent.treeParent
            ),
          });
        }
      }
    }
  } else {
    items = [];
  }

  items.unshift({ text: 'Home', urlPath: '/' });
  items.push({ text: currentPageTitle });

  return (
    items && (
      <>
        <Helmet
          script={[
            {
              type: 'application/ld+json',
              innerHTML: `{
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [
                  ${items.map(({ text, urlPath }, i) =>
                    JSON.stringify({
                      '@type': 'ListItem',
                      position: i + 1,
                      name: text,
                      ...(urlPath && {
                        item: process.env.GATSBY_SITE_URL + buildUrl(urlPath),
                      }),
                    })
                  )}
                ]
              }`,
            },
          ]}
        />
        <StyledBreadcrumbs {...props}>
          <Container>
            <StyledList>
              {items.map(({ text, urlPath }, id) => (
                <StyledItem key={id}>
                  {urlPath ? (
                    <StyledLink to={urlPath}>{text}</StyledLink>
                  ) : (
                    text
                  )}
                </StyledItem>
              ))}
            </StyledList>
          </Container>
        </StyledBreadcrumbs>
      </>
    )
  );
};

export default Breadcrumbs;

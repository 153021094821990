import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Breadcrumbs from '../components/Breadcrumbs';
import ProductDetail from '../components/ProductDetail';
import ModularBlocks from '../components/ModularBlocks';
import ProductSpecificationsDelivery from '../components/ProductSpecificationsDelivery';
import FeaturedProducts from '../components/FeaturedProducts';

const ProductPageTemplate = ({
  data: {
    shopifyProduct: {
      seo,
      noIndex,
      storefrontId,
      title,
      collections,
      mainImages,
      navImages,
      tags,
      hasOnlyDefaultVariant,
      options,
      variants,
      quantityDiscounts,
      descriptionHtml,
      colour,
      coverMaterial,
      filling,
      weight,
      size,
      packaging,
      technical,
      backing,
      fitting,
      threadCount,
      fireRetardancy,
      finish,
      washCare,
      isBestSeller,
      isAntiAllergy,
      isInSale,
      isRetailProduct,
    },
    datoCmsProduct,
    manualRelatedProducts,
    autoRelatedProducts,
    datoCmsProductSitewide,
    datoCmsSite: {
      globalSeo: { titleSuffix },
    },
  },
  pageContext: { relatedProducts: manualRelatedProductsOrder },
}) => {
  const relatedProducts =
    manualRelatedProducts.nodes.length > 0
      ? manualRelatedProducts.nodes.sort(
          (a, b) =>
            manualRelatedProductsOrder.indexOf(a.handle) -
            manualRelatedProductsOrder.indexOf(b.handle)
        )
      : autoRelatedProducts.nodes;

  return (
    <Layout noIndex={noIndex && noIndex.value === 'true'}>
      <HelmetDatoCms>
        <title>{`${seo.title || title}${titleSuffix}`}</title>
        {seo.description && (
          <meta name="description" content={seo.description} />
        )}
      </HelmetDatoCms>
      <main>
        <Breadcrumbs
          currentPageTitle={title}
          items={
            (collections &&
              collections[0] && [
                { urlPath: collections[0].handle, text: collections[0].title },
              ]) ||
            []
          }
        />
        <ProductDetail
          storefrontId={storefrontId}
          images={{
            mainImages: mainImages,
            navImages: navImages,
          }}
          title={title}
          collections={collections}
          filling={filling}
          tags={tags}
          hasOnlyDefaultVariant={hasOnlyDefaultVariant}
          options={options}
          variants={variants}
          quantityDiscounts={quantityDiscounts}
          isBestSeller={isBestSeller}
          isAntiAllergy={isAntiAllergy}
          isInSale={isInSale}
          isRetailProduct={isRetailProduct && JSON.parse(isRetailProduct.value)}
        />
        <ModularBlocks items={datoCmsProduct && datoCmsProduct.modularBlocks} />
        <ProductSpecificationsDelivery
          specificationsContent={descriptionHtml}
          specificationsData={{
            colour: colour && colour.value,
            coverMaterial: coverMaterial && coverMaterial.value,
            filling: filling && filling.value,
            weight: weight && weight.value,
            size: size && size.value,
            packaging: packaging && packaging.value,
            technical: technical && technical.value,
            backing: backing && backing.value,
            fitting: fitting && fitting.value,
            threadCount: threadCount && threadCount.value,
            fireRetardancy: fireRetardancy && fireRetardancy.value,
            finish: finish && finish.value,
            washCare: washCare && washCare.value,
          }}
          deliveryContent={datoCmsProductSitewide.deliveryInformation}
        />
        <FeaturedProducts
          heading="More Products In This Range"
          items={relatedProducts}
        />
      </main>
      <ModularBlocks items={datoCmsProductSitewide.modularBlocks} />
    </Layout>
  );
};

export const ProductPageTemplateQuery = graphql`
  query ProductPageTemplateQuery(
    $handle: String!
    $firstCollectionId: String
    $relatedProducts: [String!]
  ) {
    shopifyProduct(handle: { eq: $handle }) {
      seo {
        title
        description
      }
      noIndex: metafield(key: "no_index", namespace: "custom") {
        value
      }
      storefrontId
      title
      collections {
        title
        handle
      }
      mainImages: media {
        preview {
          image {
            gatsbyImageData(width: 680, height: 500)
            altText
          }
        }
      }
      navImages: media {
        preview {
          image {
            gatsbyImageData(width: 160, height: 140)
            altText
          }
        }
      }
      tags
      hasOnlyDefaultVariant
      options {
        name
        values
      }
      variants {
        id
        title
        storefrontId
        sku
        price
        compareAtPrice
        selectedOptions {
          name
          value
        }
        dimensions: metafield(key: "dimensions", namespace: "my_fields") {
          value
        }
        quantityDiscounts: metafield(
          key: "quantity_discounts"
          namespace: "my_fields"
        ) {
          value
        }
      }
      quantityDiscounts: metafield(
        key: "quantity_discounts"
        namespace: "my_fields"
      ) {
        value
      }
      descriptionHtml
      colour: metafield(key: "colour", namespace: "custom") {
        value
      }
      coverMaterial: metafield(key: "cover_material", namespace: "my_fields") {
        value
      }
      filling: metafield(key: "filling", namespace: "my_fields") {
        value
      }
      weight: metafield(key: "weight", namespace: "my_fields") {
        value
      }
      size: metafield(key: "dimensions", namespace: "my_fields") {
        value
      }
      packaging: metafield(key: "packaging", namespace: "my_fields") {
        value
      }
      technical: metafield(key: "technical", namespace: "my_fields") {
        value
      }
      backing: metafield(key: "backing", namespace: "my_fields") {
        value
      }
      fitting: metafield(key: "fitting", namespace: "my_fields") {
        value
      }
      threadCount: metafield(key: "thread_count", namespace: "my_fields") {
        value
      }
      fireRetardancy: metafield(
        key: "fire_retardancy"
        namespace: "my_fields"
      ) {
        value
      }
      finish: metafield(key: "finish", namespace: "my_fields") {
        value
      }
      washCare: metafield(key: "wash_care", namespace: "my_fields") {
        value
      }
      isBestSeller: metafield(key: "best_seller", namespace: "my_fields") {
        value
      }
      isAntiAllergy: metafield(key: "anti_allergy", namespace: "my_fields") {
        value
      }
      isInSale: metafield(key: "in_sale", namespace: "my_fields") {
        value
      }
      isRetailProduct: metafield(
        key: "retail_product"
        namespace: "my_fields"
      ) {
        value
      }
    }
    datoCmsProduct(shopifyProduct: { eq: $handle }) {
      modularBlocks {
        ...ContainedImageModularBlockFragment
        ...ContentModularBlockFragment
        ...ExternalVideoModularBlockFragment
        ...FullWidthImageModularBlockFragment
        ...ImageContentModularBlockV1Fragment
        ...ImageContentModularBlockV2Fragment
        ...ImagesGridModularBlockFragment
        ...InternalVideoModularBlockFragment
      }
    }
    manualRelatedProducts: allShopifyProduct(
      filter: { handle: { in: $relatedProducts } }
    ) {
      nodes {
        ...ProductCardFragment
      }
    }
    autoRelatedProducts: allShopifyProduct(
      filter: {
        handle: { ne: $handle }
        collections: { elemMatch: { id: { eq: $firstCollectionId } } }
      }
      limit: 6
    ) {
      nodes {
        ...ProductCardFragment
      }
    }
    datoCmsProductSitewide {
      deliveryInformation
      modularBlocks {
        ...IconContentBlocksModularBlockFragment
        ...LinkCardsModularBlockFragment
      }
    }
    datoCmsSite {
      globalSeo {
        titleSuffix
      }
    }
  }
`;

export default ProductPageTemplate;
